

function ButtonScrollBottom() {

    return (
        <a href="#discover" className="button-link scroll-bottom">
            Découvrir
        </a>
    )
};

export default ButtonScrollBottom;